import { Button } from "shared";
import { theme } from "shared/src/theme";
import { styled } from "styled-components";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderDrawer } from "..";
import MenuIcon from "../../../assets/menu.svg";
import { AuthContext } from "../../../utils/context";

const Container = styled.div`
  display: flex;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  height: 96px;
  align-items: center;

  & img {
    &:hover {
      opacity: 0.8;
    }
  }

  & > span {
    margin-left: auto;
    display: none;
  }

  & .transparent a {
    color: ${theme.base};
  }

  & .white-icon {
    & path {
      fill: ${theme.base};
    }
  }

  @media (max-width: 1550px) {
    padding: 0px 5%;
  }
  @media (max-width: 1440px) {
    padding: 0px 9.02% 0px 8.33%;
  }
  @media (max-width: 1245px) {
    padding: 0px 5%;
  }
  @media (max-width: 1150px) {
    padding: 0px 3%;
  }
  @media (max-width: 1095px) {
    height: 80px;
    & img {
      width: 100px;
    }
  }
  @media (max-width: 975px) {
    & img {
      width: 110px;
      height: 32px;
    }
    & > span {
      display: block;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    padding: 0px 16px;
  }
  @media (max-width: 360px) {
    height: 60px;
  }
`;

const HeaderWrapper = styled.header<{ transparent: boolean; border: boolean }>`
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 120;
  left: 0px;
  right: 0px;

  transition: all 0.4s ease-in-out;
  background: ${({ transparent }) =>
    transparent ? "transparent" : theme.base};
  box-shadow: ${({ border }) => (border ? `0 0 5px rgb(0 0 0 / 20%)` : "none")};
  border-bottom: ${({ transparent }) =>
    transparent ? "none" : `1px solid ${theme.gray3}`};

  & button {
    min-height: 48px;
    margin-left: auto;
    padding: 12px 24px;
    @media (max-width: 1095px) {
      min-height: unset;
      padding: 8px 20px;
    }
    @media (max-width: 975px) {
      display: none;
    }
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  margin-left: 48px;
  align-items: center;
  & a {
    ${theme.typography.bodySmall}
    color: ${theme.gray1};
    margin-right: 32px;

    &:hover {
      color: ${theme.buttonRed};
    }
    @media (max-width: 1035px) {
      margin-right: 12px !important;
    }
  }

  & .active {
    color: ${theme.buttonRed};
  }
  & a:last-child {
    margin-right: 0px;
  }
  @media (max-width: 1335px) {
    & a {
      margin-right: 18px;
    }
  }
  @media (max-width: 1005px) {
    & a {
      font-size: 13px;
    }
  }
  @media (max-width: 975px) {
    display: none;
  }
`;
const Header = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { closeDrawer, setCloseDrawer } = useContext(AuthContext);
  const [isTransparent, setIsTransparent] = useState(false);
  const [withBorder, setWithBorder] = useState(false);

  useEffect(() => {
    if (router.pathname === "/") {
      setIsTransparent(true);
    } else {
      setIsTransparent(false);
    }
  }, [router.pathname]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 20) {
      if (router.pathname === "/") setIsTransparent(false);
      setWithBorder(true);
    } else {
      if (router.pathname === "/") setIsTransparent(true);
      setWithBorder(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [router.pathname]);
  return (
    <HeaderWrapper transparent={isTransparent} border={withBorder}>
      <Container>
        <Link href="/">
          <a>
            <img
              src={isTransparent ? "/logo_black.svg" : "/logo.svg"}
              alt="Logo of Kansai"
              height={44}
              width={152}
            />
          </a>
        </Link>
        <LinksWrapper className={isTransparent ? "transparent" : undefined}>
          <Link href="/about">
            <a className={router.pathname === "/about" ? "active" : undefined}>
              {t("About")}
            </a>
          </Link>
          <Link href="/articles">
            <a
              className={
                router.pathname.startsWith("/articles") ? "active" : undefined
              }
            >
              {t("Articles")}
            </a>
          </Link>

          <Link href="/events">
            <a
              className={
                router.pathname.startsWith("/events") ? "active" : undefined
              }
            >
              {t("Events")}
            </a>
          </Link>

          <Link href="/startup-support">
            <a
              className={
                router.pathname === "/startup-support" ? "active" : undefined
              }
            >
              {t("Startup Supports")}
            </a>
          </Link>
          <Link href="/cities">
            <a
              className={
                router.pathname === "/cities" ||
                router.pathname?.startsWith("/city")
                  ? "active"
                  : undefined
              }
            >
              {t("Cities")}
            </a>
          </Link>

          <Link href="/keihanshin-global-hub-city">
            <a
              className={
                router.pathname === "/keihanshin-global-hub-city"
                  ? "active"
                  : undefined
              }
            >
              {t("Keihanshin Global Hub City")}
            </a>
          </Link>
        </LinksWrapper>
        <Button type="primary" onClick={() => router.push("/contact-us")}>
          {t("Contact")}
        </Button>
        <span
          onClick={() => setCloseDrawer(true)}
          className={isTransparent ? "white-icon" : undefined}
        >
          <MenuIcon />
        </span>
      </Container>

      <HeaderDrawer
        visible={!!closeDrawer}
        onClose={() => setCloseDrawer(false)}
      />
    </HeaderWrapper>
  );
};

export { Header };
