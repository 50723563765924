import { Button, i18n } from "shared";
import { Divider, Drawer, Select } from "antd";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import DownIcon from "../../../assets/caret.svg";
import TwitterIcon from "../../../assets/twitter.svg";
import FacebookIcon from "../../../assets/facebook.svg";
import CloseIcon from "../../../assets/cross.svg";
import { useRouter } from "next/router";
import { AuthContext } from "../../../utils/context";
import { useContext, useEffect } from "react";
const { Option } = Select;

interface Props {
  visible: boolean;
  onClose: any;
}

const HeaderDrawer = ({ visible, onClose }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setCloseDrawer } = useContext(AuthContext);
  const handleLanguageChange = (value) => {
    let path = router.pathname;
    if (
      router?.pathname?.startsWith("/city") ||
      router?.pathname?.startsWith("/articles/[id]")
    ) {
      path = `${router.pathname?.startsWith("/city") ? "/city" : "/articles"}/${
        router.query.id
      }`;
    }
    if (value == "en") {
      window.location?.assign(`https://en.kansai-startup-ecosystem.com${path}`);
    }
    if (value == "ja") {
      window.location?.assign(`https://kansai-startup-ecosystem.com${path}`);
    }
  };
  useEffect(() => {
    setCloseDrawer(false);
  }, [router.asPath]);

  return (
    <Drawer
      title={null}
      placement="right"
      onClose={onClose}
      closeIcon={<CloseIcon />}
      visible={visible}
      className="header-drawer"
    >
      <img alt="kansai logo" src="/logo.svg" />
      <Divider />
      <div className="links">
        <Link href="/" passHref>
          <a className={router.pathname === "/" && "active"}>{t("Home")}</a>
        </Link>
        <Link href="/about">
          <a className={router.pathname === "/about" && "active"}>
            {t("About")}
          </a>
        </Link>
        <Link href="/articles">
          <a className={router.pathname.startsWith("/articles") && "active"}>
            {t("Articles")}
          </a>
        </Link>
        <Link href="/events">
          <a className={router.pathname.startsWith("/events") && "active"}>
            {t("Events")}
          </a>
        </Link>
        <Link href="/startup-support">
          <a className={router.pathname === "/startup-support" && "active"}>
            {t("Startup Supports")}
          </a>
        </Link>
        <Link href="/cities">
          <a
            className={
              router.pathname === "/cities" ||
              router.pathname?.startsWith("/city")
                ? "active"
                : null
            }
          >
            {t("Cities")}
          </a>
        </Link>
        <Link href="/keihanshin-global-hub-city">
          <a
            className={
              router.pathname === "/keihanshin-global-hub-city" && "active"
            }
          >
            {t("Keihanshin Global Hub City")}
          </a>
        </Link>
      </div>
      <Divider />
      <h4>{t("Choose language")}</h4>
      <Select
        defaultValue={i18n.language === "ja" ? "ja" : "en"}
        suffixIcon={<DownIcon />}
        dropdownClassName="drawer-dropdown"
        onChange={handleLanguageChange}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <Option value="en">English</Option>
        <Option value="ja">日本語</Option>
      </Select>
      <h4>{t("Have any queries?")}</h4>
      <Button
        className="contact-button"
        type="primary"
        onClick={() => router.push("/contact-us")}
      >
        {t("Contact us")}
      </Button>
      <div className="follow">
        <h4>{t("Follow us: ", { keySeparator: ">", nsSeparator: "|" })}</h4>
        <Link href="https://www.facebook.com/deeptechvalleykansai">
          <a target="_blank">
            <FacebookIcon />
          </a>
        </Link>
        <Link href="https://twitter.com/deeptechvalley">
          <a target="_blank">
            <TwitterIcon />
          </a>
        </Link>
      </div>
    </Drawer>
  );
};

export { HeaderDrawer };
